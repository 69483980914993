.content-between{
    justify-content: space-between;
    display: flex;
    align-items: center;
    height: 100%;
}
.image-width{
    width: 80%;
    height: 440px;
    object-fit: contain;
}
.vertically-center{
    display: flex;
    align-items: center;
}
.imagesCount{
    color: $color-white;
}
.previewImg{
    height: 100px;
    width: 100px;
    margin-left: 5px;
    margin-right: 5px;
}
.sliderNext{
    color:$color-white;
    // position: absolute;
    top: 50%;
    bottom: 0;
    padding: 0 10px;
    opacity: .9;
    margin-top: -20px;
    z-index: 1;
    text-decoration: none;
    height: 40px;
    cursor: pointer;
}
.slider-right {
    border-top: 2px solid $color-white;
    border-right: 2px solid $color-white;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    transform: rotate(45deg);
}
.slider-left{
    border-top: 2px solid $color-white;
    border-right: 2px solid $color-white;
    width: 20px;
    height: 20px;
    margin-left: 10px;
    transform: rotate(-135deg);
}
.closeIcon{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.horizontal_scroll{
    overflow-x: auto;
    white-space: nowrap;
    display: inline-block;
    float: none;
}
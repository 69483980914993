// topbar menu styles start
.topBarImage {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0px;
    margin-bottom: 10px
}

.sidebar__link {
    width: 100%;
}

.sidebar__submenu .sidebar__link {
    padding-left: 20px;
}

.topBarImageAlignment {
    text-align: center;
}

.changemodal {
    width: 330px;
}

.themeColorText {
    color: $color-accent;
}

.paginationcss {
    margin-right: 0;
}

.topbarMenu {
    width: 200px;
    border-radius: 5px;
    border: none;
    padding-bottom: 15px 0;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    margin-top: 0;

    background-color: #000 !important;
}

.topbarMenu .topbar__link .topbar__link-title {
    color: #fff !important;
}

.topbarMenu .topbar__link .topbar__link-icon {
    color: #fff !important;
    font-weight: bold;
    line-height: 20px;
}

@media screen and (min-width: 576px) {
    .mobileView_topbar {
        display: none !important;
    }
}

@media screen and (max-width: 575px) {
    .dashboard_cards {
        padding-top: 15px;
    }
}

.mobileView_topbar {
    height: 100%;
    display: flex;
    align-items: center;
    margin-left: 37px;
}

.mobileView_topbar span {
    color: white;
    font-weight: 600;
    font-size: 25px;
    margin-top: 15px;
    margin-left: 45px;

}


.theme-white {
    border: 1px solid #ddd !important;
    background: white !important
}

.iconstyle {
    background: #d6d7da !important;
    border: 1px solid #d6d7da !important;
}


// topbar menu styles end
.form input {
    background: white;
    margin-bottom: 0.25rem;
    border: 1px solid gray;
    &::placeholder{
        color: rgb(160, 160, 160);
    }
}

.form__form-group-field label{
    margin-bottom: 0.25rem;
}

body .p-fluid .p-autocomplete .p-autocomplete-dropdown.p-button {
    width: 2em;
}

body .p-autocomplete.p-autocomplete-multiple .p-autocomplete-multiple-container .p-autocomplete-input-token input {
    font-size: 12px;
}

.p-datatable table {
    min-width: 576px !important;
}

//auto complete mwnu list is moving up
.p-autocomplete-panel {
    margin-top: 26px
}

.p-autocomplete .p-autocomplete-panel {
    top: 10% !important;
}

// Dasboard 
.dashBoardCardWithScroll {
    height: 350px;
}

//Scrollable table with Sticky Header
.table-fixed tbody {
    height: 200px;
    overflow-y: auto;
    width: 100%;
}

.table-fixed thead,
.table-fixed tbody,
.table-fixed tr,
.table-fixed td,
.table-fixed th {
    display: block;
}

.table-fixed tr:after {
    content: "";
    display: block;
    visibility: hidden;
    clear: both;
}

.table-fixed tbody td,
.table-fixed thead>tr>th {
    float: left;
}

.colorLegend {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

.p-datatable-emptymessage {
    text-align: center;
}

.sidebar__submenu-wrap {
    padding-left: 20px;
}

//autocomplete height
.p-inputtext.p-component.p-autocomplete-input.p-autocomplete-dd-input {
    height: 33px;
}

//autocomplete multi-select
.p-autocomplete-input-token {
    height: 25px;
}

.p-autocomplete-token.p-highlight {
    margin-bottom: 2px;
}

.form-control {
    width: '90%' !important
}

.form-control {
    width: '90%' !important
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
    background-color: $color-accent !important;
}

body .p-checkbox .p-checkbox-box.p-highlight {
    border-color: #ffffff !important;
    background-color: $color-accent !important;
    color: #ffffff;
}

body .p-datatable .p-datatable-tbody > tr.p-highlight {
    background-color: lighten($color-accent,41%) !important;
    color: $color-themeBlack !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item label {
    margin-top: 10px !important;
}

body .p-multiselect-panel .p-multiselect-items .p-multiselect-item {
    padding: 0 0.857em !important;
}

.fontbold {
    font-weight: bold;
}

.checkbox-btn__checkbox-custom {
    border: 1px solid $color-gray;
}

.detailsImgStyle {
    height: 120px;
}

.clientDetailsPhoto{
    height: 100px;
    width: 100px;
    border-radius: 50%;
}

.clientDetailsPageProfileContainer{
    @media screen and (max-width:576px){
        text-align: center;
    }
    @media screen and (min-width:576px) and (max-width:768px){
        text-align: left;
    }
    @media screen and (min-width:768px){
        text-align: right;
    }
}

.clientPropsScroll{
    height: 600px;
    overflow-x: hidden;
}

body .p-multiselect .p-multiselect-label {
    height: 25px;
    margin: 0em;
}

.photoViewModal.modal-show.modal-dialog {
    width: 100% !important;
    height: 90% !important;
}
.photoViewModal .modal-content{
    padding: 0px !important;
    background: transparent;
    box-shadow: none;
}
.photoViewModal .awssld__content{
    background: transparent;
}
.photoViewModal .awssld__controls__arrow-left:before, 
.photoViewModal .awssld__controls__arrow-left:after, 
.photoViewModal .awssld__controls__arrow-right:before, 
.photoViewModal .awssld__controls__arrow-right:after{
    background-color: #000;
}

.photoViewModalSliderDiv{
    display: contents;
}
.photoViewModalSliderDiv img{
    width: 80% !important;
}
.photoViewModalImg {
    height: '-webkit-fill-available' !important;
    width: '-webkit-fill-available' !important;
}
.photosModalCloseIcon{
    display: flex;
    justify-content: flex-end;
    margin: 10px
}
.closePhotosIcon{
    fill: white;
}

// Image hover css in table row
.img__wrap {
    height: auto;
    width: auto;
  }

  .img__description {
    margin-top: 0px;
    position: absolute;
    top: 90px;
    left: 50%;
    background: rgba(78, 80, 82, 0.514);
    color: #fff !important;
    visibility: hidden;
    opacity: 0;
  
    /* transition effect. not necessary */
    transition: opacity .2s, visibility .2s;
  }
  
  .img__wrap:hover .img__description {
    visibility: visible;
    opacity: 1;
  }

  .cursorPointer{
      cursor: pointer;
  }
  .bgWhite{
      background-color: $color-white !important;
  }

  .loginTranslateButton{
    width: auto !important;
    margin-left: auto;
  }

.formErrorText{
    color: $color-formError;
    font-size: 10px;
    line-height: 13px;
}

.filtersContainer{
    border: solid 1px rgb(209, 209, 209);
    border-radius: 10px;
    padding-top: 13px;
    padding-bottom: 13px;
    padding-left: 10px;
    padding-right: 0px;
    background-color: #ffffff;
}

@media screen and (max-width: 575px) {
    .hideInMobile{
        display: none;
    }
}

@media screen and (min-width: 576px) {
    .showInMobile{
        display: none;
    }
}

.activeTopBar {
    color: $color-white;
    font-weight: 900;
    &:before {
      opacity: 1;
    }
}

.emptyPropsContainer {
 height: calc(100vh - 220px);    
}

.paginatorStyles.p-paginator{
  background: none;
}

.p-fileupload-files img {
    height: 150px;
    width: 200px;
}  

.addPropertyScreen{
    height: calc(100vh - 60px);
    padding: 10px;
    background-color: $color-white;
}

.addPropertyScrollbar {
    height: calc(100vh - 80px );
}

.myPropertiesListScroll{
    height: calc(100vh - 100px);
    width: 100%;
}

.myPropertiesSearchScroll{
    height: calc(100vh - 216px);
    width: 100%;   
}

.textAreaPh {
    &::placeholder{
        color: rgb(160, 160, 160) !important;
    }
}

.propertyCardCss {
    margin: 0px 0px 10px 0px;
    padding: 0px 0px;
    border:none;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);

    &:hover {
        box-shadow: 0px 0px 2px 1px rgba($color-accent, 0.8);
        border: none;
    }
    overflow: hidden;
}

.propertyCardBodyCss{
    border-radius: 10px;
    height: auto;
    padding-bottom: 0px;
}

.propertyCardImage{
    width: 100% ;
    height: 180px;
    object-fit: cover;
}

.propertyCardFooter{
    background-color: #ffffff;
    border: none;
}

.shadowCss {
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
}

.addPropIcon{
        color: $color-accent;
    &:hover{
        color:darken($color: $color-accent, $amount: 10%);
    }
}

body .p-fileupload-choose:not(.p-disabled) {
    background-color: $color-accent;
    border-color: $color-accent;
    &:hover{
        background-color: darken($color: $color-accent, $amount: 10%);
        border-color: darken($color: $color-accent, $amount: 10%);
    }
    &:active{
        background-color: darken($color: $color-accent, $amount: 10%);
        border-color: darken($color: $color-accent, $amount: 10%);
    }
}

.textUpperCase{
    text-transform: uppercase;
}

.textCapitalize{
    text-transform: capitalize;
}

// Hide Arrows for input type number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

body .p-paginator .p-paginator-pages .p-paginator-page.p-link.p-highlight{
    background-color: $color-accent;
    border-radius: 50%;
    &:focus{
        // box-shadow: 0 0 0 0.2em darken($color:$color-accent, $amount: 0);
        box-shadow: none;
    }
}
body .p-paginator .p-paginator-pages .p-paginator-page.p-link{
    border-radius: 50%;
    &:focus{
        // box-shadow: 0 0 0 0.2em darken($color:$color-accent, $amount: 0);
        box-shadow: none;
    }
}
body .p-paginator .p-paginator-first, 
body .p-paginator .p-paginator-prev, 
body .p-paginator .p-paginator-next, 
body .p-paginator .p-paginator-last {
    border-radius: 50%;
    &:focus{
        box-shadow: none;
    }
}

.clientsTableImgStyles {
    width: 50px !important;
    height: 50px !important;
    border-radius: 50%;
}

.propertyCheckBox{
    position: absolute;
    left: 2px;
    top: 2px;
}

.propertyCheckBox .checkbox-btn__checkbox-custom{
    background-color: white;
}

.propertyPriceOverlay{
    position: absolute;
    z-index: 1;
    // left: 0px;
    padding: 3px 5px;
    color:$color-white;
    font-weight: 600;
    font-size: 14px;
}
.propertyPriceOverlay.rent{
    top: 130px;
    background-color:$color-themeBlue;
}
.propertyPriceOverlay.sale{
    top: 152px;
    background-color:$color-themeBlue;
}

.propertyPriceLabelOverlay {
    position: absolute;
    z-index: 1;
    right: 0px;
    padding: 3px 5px;
    color: $color-white;
    font-weight: 600;
    font-size: 14px;
}

.propertyPriceLabelOverlay.label {
    top: 152px;
    background-color: $color-themeBlue;
}

.cornerRibbon{
    width: 100px;
    background: $color-accent;
    position: absolute;
    top: 18px;
    left: auto;
    right: -22px;
    text-align: center;
    line-height: 20px;
    letter-spacing: 1px;
    color: $color-white;
    font-weight: 800;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.howLongText {
    color: rgb(99, 99, 99);
}   

.howLongIcon {
    height: 17px;
    width: 17px;
    vertical-align: sub;
}
.shareIconAlignment {
    margin: 0px;
    width: 16px;
    height: 16px;
    fill:$color-themeBlue;
    &:hover{
        fill: $color-accent;
    }
}

.viewMoreLink{
    vertical-align: text-bottom;
    z-index: 1;
    color: rgb(99, 99, 99);
    fill: rgb(99, 99, 99);
    &:hover{
        color: $color-accent;
        fill: $color-accent;
    }
}

.viewMoreIconAlignment{
    width: 18px;
    height: 18px;
    vertical-align: sub;
}

.secondaryText{
    color: $color-gray;
}

.formModalBorderRadius .modal-content{
    border-radius: 10px;
}
.formModalBorderRadius .modal-header{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.formPhotoField{
    position: absolute;
    right: 0px;
    color: #000000;
    cursor: pointer;
}

//Select/Dropdown Field Custom Css
.css-1szy77t-control{
    border-color: $color-accent !important;
    box-shadow: 0 0 0 0px $color-accent !important;
    border-radius: 5px !important;

    &:focus{
        border-color: $color-accent !important;
        box-shadow: 0 0 0 0px $color-accent !important;
    }

    &:hover{
        border-color: $color-accent !important;
        box-shadow: 0 0 0 0px $color-accent !important;
    }
}

.css-bg1rzq-control{
    border-radius: 5px !important;
}

.addPropBackLink{
    background-color: $color-accent;
    color: #ffffff;
    font-weight: 600;
    &:hover{
        color: #ffffff;
    }
}

.blackText {
    color: $color-themeBlack !important;
}

.propertDetailsPadding{
   padding-bottom: 12px;
}

.clientCriteriaCard{
    border: 0px solid $color-accent !important;
    box-shadow: 0px 0px 1px 1px $color-accent !important;
    z-index: 1;
    &:hover {
        box-shadow: 0px 0px 2px 2px $color-accent !important;
    }
}

.passwordInfoIcon{
    fill: $color-accent;
}
.viewMatchedEyeIcon {
    vertical-align: bottom;
    z-index: 1;
    color: rgb(190, 190, 190);
    fill: rgb(190, 190, 190);
    &:hover{
        color: $color-accent;
        fill: $color-accent;
        cursor: pointer;
    }
}

.invalidFilterModal{
    max-width: 400px;
}
.invalidFilterModal .modal-content{
    padding: 10px 20px !important;
}

.contactInfoIcon{
    height: 15px;
    width: 15px;
    margin-left: 4px;
    fill: $color-themeBlue;
    vertical-align: sub;
}
.contactInfoToggle1{
    color: $color-themeBlue;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
}
.contactInfoToggle2{
    color: $color-accent;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 500;
}

.showContactText{
    height: 40px;
}

.backIcon {
    fill: $color-accent;
    height: 38px;
    width: 38px;
    margin-top: 1px;
    margin-bottom: 1px;
    &:hover{
        fill: darken($color: $color-accent, $amount: 5%);
        height: 40px;
        width: 40px;
        margin-top: 0px;
    margin-bottom: 0px;
    }
}
.propertyDetailsShareIcon{
    fill: $color-accent;
    height: 27px;
    width: 27px;
}

.criteriaUpdateCss{
    &:hover{
        background-color: $color-accent !important;
        border-color: $color-accent !important;
    }
    &::before{
        background-color: $color-accent !important;
        border-color: $color-accent !important;
    }
}

.propDetailsPhotoSlider .awssld__content{
    background-color:transparent;
}

.matchedPropsButtonRow{
    @media screen and (max-width:576px) {
        margin-left:13px !important;
        margin-right:13px !important;
    }
}

.globalShareIcon_icon{
    background-color: #000;
   height: 18px;
   width: 18px;
   border-radius: 50%;
   margin-right: 6px;
   color: #ffffff;
    padding-right: 2px;
    vertical-align: sub;
    margin-left: 1px;
}

.globalShareIcon_label{
    color: #646777;
}

.globalShareIcon {
    &:hover{
        .globalShareIcon_icon{
            background-color: $color-accent;
        }
        .globalShareIcon_label{
            color: $color-accent;
        }
        cursor: pointer;
    }
}

.clientFormPhotoField{
    position: absolute;
    left: 0px;
    cursor: pointer;
    z-index: 1;
    color: red;
}

.description_alingment{
    // margin-top: 10px;
    align-self: center;
    padding-left: 10px;
    padding-right: 10px;
    @media screen and ( min-width:575px  ) and ( max-width:768px){
       height: 190px;
    }
}
.clientImage{
    height: 200px;
    // object-fit: cover;
    @media screen and (min-width:768px) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
    }
    @media screen and (max-width:768px) {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
     }
}
.clientMyProps{
    justify-content: center;
    border: 1px solid $color-accent;
    border-radius:10px;
    &:hover {
        box-shadow: 0px 0px 4px 1px $color-accent;
    }
}

.propertiesTitleToShareDiv{
    border: 1px solid $color-accent;
    border-radius: 5px;
    padding: 5px;
    font-weight: 500;
}

.linkOverCardCss {
    color: $color-themeBlack;
    &:hover {
        color: $color-themeBlack;
    }
}

.dashBoardCountCardCss{
    &:hover {
        box-shadow: 0px 0px 2px 0.5px $color-accent;
    }
    color: $color-accent;
    font-size: 19px;
}

.awssld__controls__arrow-right{
    height:30px !important;
}
.awssld__controls__arrow-left {
    height:30px !important;
}

// PrimeReact FileUpload
body .p-button{
    background-color: $color-accent !important;
    border-color: $color-accent !important;
    border-radius: 2px !important;
    font-weight: 600;
}

body .p-fileupload .p-fileupload-buttonbar .p-button.p-fileupload-choose.p-focus{
    box-shadow: none;
}

body .fileUploadComponent .p-button:nth-child(2){
    display: none;
}
body .ud_uploadPhotoButton .p-button-icon-left{
    display: none;
}
body .ud_uploadPhotoButton .p-button .p-clickable{
    padding: 0.429em 1em 0.429em 1em !important;
}

body .ud_uploadPhotoButton .p-button{
    max-width: 128px !important;
} 

.editPropRemovePicIcon{
    position: absolute;
    right: 5px;
    fill: $color-accent;
    cursor: pointer;
    z-index: 10;
}

.profileEditImage {
    width: 75px;
    height: 75px;
    border-radius: 50%;
    margin: 0px;
}

.buttonTextWeight {
    font-weight: bold;
}

.myPropDeleteIcon {
  align-self: center;
}

.myPropEditIcon{
    vertical-align: text-bottom;
}

body .fileUploadComponent .p-fileupload-content{
    max-height: 340px;
    overflow-y: auto !important;
    @media screen and (max-width:768px){
        max-width: 87vw ;
    }
    @media screen and (min-width:768px){
        max-width: 44vw ;
    }
}

body .fileUploadComponent .p-fileupload-content .p-fileupload-files .p-fileupload-row>div:nth-child(2){
  display: none;
}
body .fileUploadComponent .p-fileupload-content .p-fileupload-files .p-fileupload-row>div:nth-child(3){
    display: none;
}

.registrationForm{
    max-width: 500px;
}

.pageHeadingCss {
    text-transform: uppercase;
    font-size: 20px;    
    font-weight: 400;
}

.pageSubHeadingCss {
    text-transform: uppercase;
    font-size: 17px;    
}

.propertyCardHeading {
    font-size: 16px;
    font-weight: 500;
}

.dashboardCountIcon {
    width: 35px;
    height: 35px;
    color: $color-accent;
}

.dashboardCountTitle{
    padding: 20px 0px;
    font-size: 16px;
    color: $color-themeBlack;
    text-transform: uppercase;
}

.dashboardCountNumber{
    font-size: 22px;
    font-weight: 400;
}

.propertyCardDetails{
    font-size: 13px !important;
}

.detailsFont {
    font-size: 14px !important;
}

.clientCriteriaCardHeading {
    font-size: 17px !important;
}

.lightText {
    color: #4d4d4d;
}

.propTitleToShare{
    background-color: #e2e2e2;
    margin: 4px;
}
.css-1hwfws3{
    height: 32px !important;
}

.data {
    display: inline-block;
  }
  
   .dataText {
    visibility: hidden;
    background-color: $color-accent;
    color: $color-white;
    text-align: center;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    z-index: 1;
    margin-top: 10px;
    margin-top: 10px;
    white-space: break-spaces;
    text-align: left;
  }
  
  .data:hover .dataText {
    visibility: visible;
  }

  .p-datatable-scrollable-body{
    position: unset !important;
  } 

.addPropDropZone.dropzone.dropzone--multiple{
    min-height: 200px;
    height: 220px !important;
    overflow-y: auto;
}
.addPropDropZone .dropzone__input{
    height: 200px;
    min-height: 200px !important;
    outline: none;
}
.addPropDropZone .dropzone__img-delete{
    right: 4px;
}

.react-select__input input{
    font-size: 100%;
    height: 14px;
    margin-bottom: 0px;
}
.react-select__value-container{
    position: unset !important;
}
.react-select .css-1g6gooi{
    margin: 0px 0px 3px 0px;
}
.cityFilter .css-1g6gooi{
    margin: 2px 0px 0px 0px !important;
}
.cityFilter .react-select__clear-indicator{
    padding: 5px !important;
}
.locFilter .css-1g6gooi{
    margin: 2px 0px 0px 0px !important;
}
.locFilter .react-select__clear-indicator{
    padding: 5px !important;
}
.css-151xaom-placeholder{
    margin: 0px !important;
}
.css-dvua67-singleValue{
    margin: 0px !important;
}
.clientSearch .react-select__clear-indicator {
    padding: 0px 0.5rem !important;
}
.clientSearch .react-select__input input{
    font-size: 100% !important;
    height: 14px;
    margin-bottom: 0px;
    margin-top: 5px;
}

.landingBg {
    background: url('../assets/img/landingBg.jpg');
    background-position: center;
    background-size: cover;
    height: calc(100vh - 60px);
}
.landingBg.container{
    @media screen and (min-width: 576px){
        min-width: 100% !important;
    }
}

.landingHeadingCss {
    color: $color-white !important;
    padding: 30px 0px;
    font-weight: 400;
    font-size: 40px;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.51);
    span {
        font-weight: 500;
    }
}
.landingSearchCard{
    background-color: rgba(0,0,0,0.5);
    backdrop-filter: blur(0px);
    margin: auto;
    padding: 25px 70px 35px 70px;
    @media screen and (min-width : 1300px){
        width: 60% !important;     
        padding: 25px 80px 35px 80px;   
    }
    @media screen and (min-width : 1024px) and(max-width : 1300px){
        width: 60% !important;     
        padding: 25px 30px 35px 30px;   
    }
    @media screen and (min-width : 786px)and(max-width : 1024px){
        width: 65% !important;    
        padding: 25px 40px 35px 40px;
    }
    @media screen and (min-width : 576px)and(max-width : 786px){
        width: 80% !important;    
    }
    @media screen and (max-width : 576px){
        width: 98% !important;     
        padding: 25px 10px 35px 10px;   
    }
}

.locationSelection .css-bg1rzq-control{
    border-radius: 0px 0px 0px 0px !important;
    border-width: 0px !important;
    border-left-width: 1px !important;
}
.locationSelection .css-1szy77t-control{
    border-radius: 0px 0px 0px 0px !important;
    border-width: 0px !important;
    border-left-width: 1px !important;
    border-left-color: hsl(0,0%,80%) !important;
    &:hover,:focus{
        border-radius: 0px 0px 0px 0px !important;
        border-width: 0px !important;
        border-left-width: 1px !important;
        border-left-color: hsl(0,0%,80%) !important;
    }
}
.citySelection .css-bg1rzq-control{
    border-radius: 0px 0px 0px 0px !important;
    border-color: transparent !important;
}
.citySelection .css-1szy77t-control{
    border-radius: 0px 0px 0px 0px !important;
    border-color: transparent !important;
    &:hover,:focus{
        border-radius: 0px 0px 0px 0px !important;
        border-color: transparent !important;
    }
}
.citySelection .css-1g6gooi{
    margin: 0px !important;
}
.locationSelection .css-1g6gooi{
    margin: 0px !important;
}

.localitySearchIndicator {
    background-color: $color-accent !important;
    color: $color-white !important;
    padding: 9px 12px !important;
    height: 38px !important;
    text-align: center;
    width: 100%;
    cursor: pointer;
    svg {
        height: 20px;
        width: 20px;
        margin-right: 5px;
    }

    @media screen and (min-width : 786px)and(max-width : 1034px){
        padding: 9px 4px !important;
        span{
            padding-right: 0px;
        }
        svg {
            height: 17px;
            width: 16px;
            margin: 0px;
        } 
    }
    @media screen and (min-width : 500px)and(max-width : 786px){
        padding: 9px 10px !important;
        span {
            display: none;
        }
        svg {
            height: 24px;
            width: 24px;
            margin: 0px;
        } 
    }
    @media screen and (max-width : 500px){
        padding: 9px 10px !important;
        span {
            display: none;
        }
        svg {
            height: 24px;
            width: 24px;
            margin: 0px;
        } 
    }
    a {
        color: $color-white;
        &:hover{
        color: $color-white;
        }
    }
    span {
        font-weight: 600;
    }
}

.searchTab{
    font-size: 14px !important;
    line-height: 25px !important;
    text-transform: uppercase;
    color: rgba(255,255,255,0.7);
    cursor: pointer;
    &:hover{
        color: rgba(255,255,255,0.7);
    }
}
.activeSearchTab {
    font-size: 14px !important;
    line-height: 25px !important;
    text-transform: uppercase;
    cursor: pointer;
    opacity: 0.9;
    color: #fff !important;
    border-bottom: 2px solid #fff;
}

.socialLoginsDiv {
    margin: 0px;
    text-align: center;
    position: relative;
    overflow: hidden;
    max-height: 350px;
}
.socialIconSpan {
    margin: 15px 10px;
    cursor: pointer;
    rect{
        stroke: rgb(201, 201, 201);
    }
    &:hover{
        rect{
            stroke: rgb(99, 99, 99);
        }
    }
    svg{
        margin-top: 15px;
    }
}
.socialLoginTitle2 {
    padding: 4px;
    color: rgba(0, 0, 0, 0.5);
    width: 112px;
    opacity: 0.4;
    margin: 10px auto 8px auto;
}
.socialLoginTitle1 {
    padding: 12px 4px 8px 4px;
    color: rgba(0, 0, 0, 0.5);
    width: 100%;
    opacity: 0.4;
    margin-bottom: 25px;
    height: 40px;
    border-bottom: 1px solid rgba($color-themeBlack,0.2);
    h5{
        position: absolute;
        z-index: 12;
        left: calc(50% - 46px);
        background: white;
        padding: 0px 5px;
        height: 40px;
        margin: 18px 0px;
    }
}
.socialLoginHomeLink{
    padding: 0px;
    color: $color-accent !important;
    margin: 0px auto;
}

// Property Details Screen
.pd_headerContainer {
    @media screen and (min-width:768px)
    {
        width: 60%;
        margin: auto;
    }
    @media screen and (min-width:576px)and (max-width:768px)
    {
        width: 90%;
        margin: auto;   
    }
    @media screen and (max-width:576px)
    {
        width: 100%;
        margin: auto;
    }
}
.pd_head_titleContainer{
    margin-top: 20px;
}
.pd_address {
    margin-top: 8px;
    font-size: 17px;
    line-height: 17px;
    color: #7f7f7f;
}
.pd_title {
    line-height: 29px;
    display: flex;
}
.pd_favIcon {
    color:$color-pink;
    cursor: pointer;
    margin-left: 15px;
    &:hover{
        color: $color-themeBlack;
    }
}
.pd_head_priceContainer{
    margin-top: 20px;
    text-align: right;
    @media screen and (max-width:575px){
        text-align: left;
    }
}
.pd_head_area{
    margin-top: 8px;
    font-size: 17px;
    line-height: 17px;
    color: #7f7f7f;
}
.pd_head_contactButton{
    margin-top: 8px;
}

.pd_gallery_container{
    margin-top: 20px;
    padding: 0px;

    @media screen and (min-width:768px)
    {
        padding-top: 30px;
        width: 55%;
        margin: auto;
    }
    @media screen and (min-width:576px)and (max-width:768px)
    {
        width: 90%;
        margin: auto;  
    }
    @media screen and (max-width:576px)
    {
        width: 100%;
        margin: auto;
        padding: 10px;
    }
}
// .pd_galleryGrid {
//     object-fit: cover;
//     display: block;
//     width: 100%;
//     height: 420px;
//     margin-top: 16px;
//     display: grid;
//     grid-template-columns: 70% 1fr;
//     grid-template-rows: 50% 50%;
//     grid-column-gap: 7px;
//     grid-row-gap: 7px;
//     @media screen and (min-width:768px)and (max-width:1024px)
//     {
//         height: 400px;
//         grid-template-columns: 70% 1fr;
//     }
//     @media screen and (min-width:576px)and (max-width:768px)
//     {
//         height: 300px;
//         grid-template-columns: 50% 1fr;
//     }
//     @media screen and (max-width:576px)
//     {
//         height: 240px;
//         grid-template-columns: 100% 1fr;
//     }
// }
.pd_imageCount{
       position: absolute;
       top: 0%;
       left: 0%;
       height: 100%;
       width: 100%;
       background-color: rgba(0,0,0,0.7);
       backdrop-filter: blur(2px);
       color: #ffffff !important;
       margin: 0px;
       font-size: 20px;
       font-weight: 500;
       cursor: pointer;
       text-align: center;
       @media screen and (max-width:576px){
           display:none
       }
}
.pd_imageCountText{
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 40px);
    @media screen and (max-width:576px){
        display:none
    }
}
.pd_gridImage1{
    grid-row: 1 / 3;
    position: relative;
}
.pd_gridChild{
    position: relative;
}
.pd_gridImageChild{
    animation: 300ms linear 0s 1 normal none running;
    width: 100%;
    height: 100%;
    cursor: pointer;
    object-fit: cover;    
}

.pd_noImage{
    object-fit: fill;
    @media screen and (min-width:768px)
    {
        height: 420px;
    }
    @media screen and (min-width:576px)and (max-width:768px)
    {
        height: 280px;
    }
}

.pd_singleImage{
    object-fit: cover;
    cursor: pointer;
    @media screen and (min-width:768px)
    {
        height: 420px;
    }
    @media screen and (min-width:576px)and (max-width:768px)
    {
        height: 280px;
    }
}

.pd_baseInfo_container{
    margin-top: 20px;
    @media screen and (min-width:768px)
    {
        width: 60%;
        margin: 20px auto 0px auto;
    }
    @media screen and (min-width:576px)and (max-width:768px)
    {
        width: 90%;
        margin: 20px auto 0px auto;
    }
    @media screen and (max-width:576px)
    {
        width: 100%;
        margin: auto;
    }
    div{
        color: #999;
    }
}
.pd_baseInfoItem {
    padding: 8px 22px;
    // text-align: center;
    div{
        font-size: 14px;
    }
}

.pd_navbar_container{
    background-color:$color-white;
    border-top: 1px solid #f5f5f5;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.11);
}

.pd_data_nav{
    position: sticky;
    display: flex;
    top: 44px;
    z-index: 2;
    width: 100%;
    height: 60px;
    text-transform: uppercase;
}
.pd_data_nav_tab{
    padding: 0px;
    border-bottom: 2px solid transparent;
    line-height: 58px;
    font-weight: 500;
    font-size: 18px;
    white-space: nowrap;
    border-bottom-color: $color-accent;
    white-space: nowrap;
    color: $color-accent;
    @media screen and (min-width:768px)
    {
        margin-left: 120px !important;
        margin-right: 120px !important;   
    }
    @media screen and (min-width:576px)and (max-width:768px)
    {
        margin-left: 0px !important;
        margin-right: 0px !important;   
    }
}

.pd_data_container{
    @media screen and (min-width:768px)
    {
        width: 60%;
        margin: auto;
    }
    @media screen and (min-width:576px)and (max-width:768px)
    {
        width: 90%;
        margin: auto;   
    }
    @media screen and (max-width:576px)
    {
        width: 100%;
        margin: auto;
    }
}
.pd_data_content{
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.pd_nav_content_overview{
    background:white;
    padding: 0px 0px;
    h3{
        padding: 20px 10px;
        border-bottom: 1px solid #eee;
        line-height: 18px;
        width: 100%;
    }
    @media screen and (min-width:768px)
    {
        margin-right: 5px !important;   
    }
    @media screen and (min-width:576px)and (max-width:768px)
    {
        margin-right: 0px !important;   
    }
}
.pd_overview_data{
    font-size: 16px;
    font-weight: 400;
    margin-top: 20px;
    div{
        color: #999;
    }
}
.pd_overview_data:last-of-type{
margin-bottom: 20px;
}
.pd_nav_content_contact{
    padding: 12px 16px;
    overflow: auto;
    background: rgb(255, 255, 255);
    height: 100%;
    @media screen and (min-width:768px)
    {
        margin-left: 5px !important;   
    }
    @media screen and (min-width:576px)and (max-width:768px)
    {
        margin-left: 0px !important;   
    }
}
.pd_contact_childOne{
    line-height: 18px;
    font-size: 16px;
    padding: 0px 10px;
    margin-bottom: 10px;
}
.pd_contact_childTwo{
    display: flex;
    padding: 0px 16px;
    border: none;
    position: relative;
    margin-bottom: 16px;
    align-items: center;
}
.pd_contactForm{
    padding: 2px 16px;
    border: none; 
    .form{
        display: block;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 26px 0px;
        padding:16px 16px 6px 16px;
        border-radius: 6px;
        border: 1px solid rgb(235, 235, 235);
        background-color: rgb(255, 255, 255);
    }
    input{
        margin: 6px 0px;
        border: none !important;
        border-bottom: 1px solid #e6e6e6 !important;
        border-radius: 0px !important;
        padding: 0px !important;
    }  
}

.contactSellerPhoneEdit {
    color: $color-accent;
    height: 16px;
    width: 16px;
    margin-left: 2px;
    cursor: pointer;
    vertical-align: top;
}

.propertyCardFavIcon{
    height: 25px;
    width: 18px;
    cursor: pointer;
    &:hover{
        color: $color-pink;
    }
}
.propertyCardUnFavIcon{
    height: 25px;
    width: 18px;
    cursor: pointer;
    color: $color-pink;
    &:hover{
        color: initial;
    }
}
.propertyCardFavIconTrue{
    position: absolute;
    right: 0px;
    color: $color-pink;
    cursor: pointer;
    &:hover{
        color: initial;
    }
}

.otpVerifyModal{
    max-width: 400px;
}
.otpVerifyModal .modal-content{
    padding: 10px !important;
}
.otpVerifyModal .pd_nav_content_contact{
    margin: 0px !important;
    padding: 10px 0px !important;
}
.otpVerifyModal .pd_contact_childOne,.pd_contact_childTwo,.pd_contactForm{
    padding: 0px 10px !important;
}
.otpVerifyModal{
    .pd_contactForm .form{
       box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 10px 0px !important;
    }
}
.otpVerifySuccessText{
    svg{
        height: 19px;
        width: 20px;
    }
}
.useVerifiedNumberText{
    padding: 3px 0px;
}
.otpInput {
    input {
        width: 30px !important;
        padding: 0px !important;
        border: none !important;
        border-bottom: 1px solid $color-gray !important;
        border-radius: 0px !important;
        margin-bottom: 20px !important;
        font-size: 20px !important;
    }
}
.redendOtpText{
    justify-content: flex-end;
    color: $color-accent;
    cursor: pointer;
}
.otpModalButtons {
    margin: 5px 5px 0px 5px;
    font-weight: bold;
}

.topBarNotifIcon{   
    height: 24px; 
    @media screen and (min-width :900px){
        padding: 0px 35px 0px 10px !important;
    }

    @media screen and (min-width: 720px) and (max-width: 900px) {
        padding: 0px 15px 0px 10px !important;
    }

    @media screen and (min-width: 576px) and (max-width: 720px) {
        padding: 0px 6px 0px 10px !important;
    }
}
.notifCount {
    position: relative;
    bottom: 26px;
    left: 15px;
    background: white;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    font-size: 11px;
    text-align: center;
    cursor: pointer;
}
.notifCollapse{
    z-index: 1;
    position: absolute;
    width: 100% !important;
    padding: 10px;
    right: 0px;
    top: 40px;
    max-width: 500px;
    .card,.card-body{
        background-color: $color-themeBlack;
        p{
            color: $color-white;
        }
        h4{
            color: #ffffff;
            padding-bottom: 10px;
            border-bottom: 1px solid #999;
            margin-bottom: 3px;
        }
    }
    @media screen and (max-width:400px){
     width: 280px !important;   
    }
}
.notifCollapse .modalToggleButton{
    color: $color-white !important;
    :hover{
        color: $color-white !important;
    }
}
.notifItem{
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
    padding: 10px 0px 10px 0px;
    border-bottom: 1px solid #999;
}
.notifItem:last-of-type{
    border-bottom:none;
}
.notifItemIcon{
    border: 1px solid #999;
    border-radius: 50%;
    padding: 5px !important;
    svg{
        color: $color-accent;
    }
}
.notifListScroll{
    max-height: 300px;
    margin-bottom: 1px;
}
.notifBellIcon {
    cursor: pointer;
}
.profileStaticCol{
    margin: 0px;
    border-radius: 30px;
    background-color: #e8ecf3 !important;
    padding-bottom: 40px;
    @media screen and (max-width:575px){
        margin: 5px 0px 22px 0px;
    }
}
.ud_staticProfDiv{
    text-align: center;
    margin-bottom: 6px;
    height: 232px;
}
.ud_name{
    text-align: center;
    position: relative;
    display: block;
    top: 49px;
    font-size: 16px;
    font-weight: 500;
    color: rgb(66, 66, 66);
}
.ud_email{
    text-align: center;
    position: relative;
    display: block;
    top: 55px;
    font-size: 12px;
    font-weight: 500;
    color: rgb(127, 127, 127);
}
.ud_photo{
    border-radius: 50%;
    margin: 0px auto;
    height: 130px;
    width: 130px;
    border: 5px solid $color-white;
    position: relative;
    top: 29px;
    background: rgb(255, 255, 255);
}
.ud_photo_bg{
    animation: 300ms linear 0s 1 normal none running animation-1qbobka;
    object-fit: cover;
    width: 245px;
    position: absolute;
    left: -64px;
    img{
        border: none;
        outline: none;
    }
}
.ud_photo_user{
    animation: 300ms linear 0s 1 normal none running animation-1qbobka;
    object-fit: cover;
    height: 116px;
    width: 116px;
    border-radius: 50%;
    position: absolute;
    top: 2px;
    left: 2px;
    img{
        border: none;
        outline: none;
    }
}
.ud_staticNavDiv{
    background-color: rgb(255, 255, 255);
    border-radius: 30px;
    padding: 20px 0px;

    a{
        font-size: 14px;
        line-height: 42px;
        margin-top: 8px;
        color: rgb(65, 65, 65);
        padding: 0px 24px;
        display: block;
        border: none;
        outline: none;
        &:hover{
            background: rgb(247, 247, 247);
            color: $color-accent;
            cursor: pointer;
        }
    }
    svg{
        margin-right: 12px;
        height: 20px;
    }
    hr{
        opacity: 0.3;
        margin: 16px 16px 0px;
    }
}

.ud_staticNavDiv p{
    font-size: 14px;
    line-height: 42px;
    margin-top: 8px;
    color: rgb(65, 65, 65);
    padding: 0px 24px;
    display: block;
    border: none;
    outline: none;
    color: $color-accent !important;
    margin-bottom: 8px;
    cursor: pointer;
}

.ud_edit_div{
    width: 100%;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(230, 230, 230);
    padding: 30px;
    margin-top: 22px;
    justify-content: center;
}
.ud_contactForm{
  align-self: center;
  padding-top: 10px;
  .form__form-group-label{
    margin-bottom: 0px;
    font-size: 9px;
    font-weight: 500;
    color: rgba(0,0,0,0.9);
    text-transform: uppercase;
  }
  input{
    background: none !important;
    padding: 0 10px 0px 0px !important;
    margin-top: 9px !important;
    border-width: 0 !important;
    font-size: 16px !important;
    border-bottom: 1px solid rgba(0,0,0,0.1) !important;
    width: 100% !important;
    box-shadow: none !important;
    outline: none !important;
    height: 22px !important;
    border-radius: 0px !important;
    color: rgba(0,0,0,0.7) !important;
  }
}
.ud_contact_photo{
    width: 128px;
    height: 128px;
    border-radius: 50%;
    display: inline-block;
    align-self: center;
    text-align: center;
    border: 1px solid rgba($color-accent,0.5);
    img{
    width: 126px;
    height: 126px;
    border-radius: 50%;
    object-fit: cover;
    }
}
.ud_uploadPhotoButton{
    margin-top: 10px;
}
.ud_chnagePhoneTitle{
    line-height: 14px;
    font-size: 12px;
    color: #8a8a8a;
    text-transform: uppercase;
}
.ud_phoneOtpButton{
    margin: 0px;
    font-weight: bold;
}

.saveSearchModal {
    .modal-content{
        padding: 20px !important;
        margin: auto;
        max-width: 400px;
    }
    .ss_content_div{
        border: 1px solid rgb(230, 230, 230);
        border-radius: 2px;
        margin-bottom: 15px;
        font-size: 12px;
        padding: 0px 15px;
        .ss_content_title{
            font-weight: 500 !important;
            padding-bottom: 8px !important;
            width: 100% !important;
            font-size: 12px;
            color: rgb(127, 127, 127);
            margin-top: 10px;
            border-bottom: 1px solid rgb(230, 230, 230);
            margin-bottom: 8px;
        }
        .ss_content_data{
            margin: 10px 0px;
            text-align: left;
            svg{
                height: 17px;
                width: 20px;
            }
        }
    }
}

.listScreenHeaderButtonCss{
    @media screen and (max-width:576px){
        div{
            text-align: right !important;
        }
        button{
            width: 143px;
            margin-bottom: 8px !important;
        }
    }
}
.listScreenHeaderTitleDivCss{
    margin: 5px auto 13px auto;
}
.modalToggleButton{
    position: absolute;
    z-index: 1;
    right: 7px;
    top: 0px;
    font-size: 2rem;
    font-weight: 100;
}
.listScreenPaginator {
    @media screen and (max-width:576px) {
        position: fixed;
        bottom: 0px;
        width: 100%;
        .col{
            padding-left: 0px;
            padding-right: 0px;
        }
    }
}

.custom-gallery .image-gallery-slide img {
    @media screen and (min-width: 768px) {
            width: 100%;
            height: 45rem;
            object-fit: cover;
        }   
}

.custom-card-gallery {
    .image-gallery-play-button{
        display: none;
    }
    .image-gallery-fullscreen-button{
        display: none;
    }
}

.custom-card-gallery .image-gallery-slide {
    width: 100%;
    max-width: 100%;
    height: 181px;
}

.custom-card-gallery .image-gallery-left-nav,
.custom-card-gallery .image-gallery-right-nav {
    width: 40px;
    height: 30px;
    line-height: 30px;
    background-size: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.details_header{
    background-color: white;
}

.topbar_backIcon {
    width: 80px;
    height: 24px;
    margin: auto 0;
    margin-left: 10px;
}
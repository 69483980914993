$themes: (
  light: (
    colorBackground: white,
    colorBackgroundBody: #f2f4f7,
    colorText: #212529,
    colorTextAdditional: #646777,
    colorHover: #fef6f0,
    tableHead: #e9ebee,
    colorFolderHover: #f0eeee,
    colorBorder: #eff1f5,
    colorIcon: #cdd4da,
    imgInvert: invert(0%),
    colorFieldsBorder: #ced4da,
    colorBubble: rgba(242, 244, 247, 0.65),
    colorBubbleActive: rgba(234, 238, 255, 0.6),
    colorScrollbar: #B4BFD0,
    colorFitness: #646777,
    logoImg: url(../assets/img/logo/logo_mls.jpeg),
  ),
  dark: (
    colorBackground: #212529,
    colorBackgroundBody: #2a2a31,
    colorText: #dddddd,
    colorTextAdditional: #999999,
    colorHover: #38373f,
    tableHead: #38373f,
    colorFolderHover: #ffffff1A,
    colorBorder: #333246,
    colorIcon: #605f7b,
    imgInvert: invert(100%),
    colorFieldsBorder: #33333a,
    colorBubble: rgba(68, 79, 97, 0.65),
    colorBubbleActive: rgba(92, 104, 156, 0.6),
    colorScrollbar: #606071,
    colorFitness: #ffffff,
    logoImg: url(../assets/img/logo/logo_mls.jpeg),
  )
);

@mixin themify($themes) {
  @each $theme, $map in $themes {
    .theme-#{$theme} & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge($theme-map, ($key: $value)) !global;
      }
      @content;
      $theme-map: null !global;
    }
  }
}

@function themed($key) {
    @return map-get($theme-map, $key);
}

$color-template: #ff7b00;
$color-accent: #3C6177;
$color-accent-hover: darken($color-accent, 10%);

$color-themeBlue : #3C6177;
$color-themeBlack : #212529;

$color-gray: #787985;
$color-light-gray: #d8dfe9;

$color-white : #ffffff;
$color-pink : #e62878;

$color-additional: #424242;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #ff7b00;
$color-blue-hover: darken($color-blue, 10%);

$color-green: #b8e986;
$color-green-hover: darken($color-green, 10%);

$color-topbar-link : #000000;
$color-topbar-link-hover : red;
$color-topbar-link-active : rgb(119, 97, 97);

$color-formError : #ad4444;
